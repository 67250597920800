import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import homeslider1 from "../../assets/image/homeslider.webp";
import homeslider2 from "../../assets/image/homeslider2.webp";
import { NavLink } from "react-router-dom";
import translations from "../../data/langData";
import { LanguageContext } from "../../context/LanguageContext";
import LazyLoad from "react-lazy-load";
import ApiLinkContext from "../../context/ApiLinkContext";

const Slider = () => {
  const { data } = useContext(ApiLinkContext);
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  return (
    <>
      <div className="tf-slideshow slider-home-4 slider-effect-fade position-relative">
        <div
          className="swiper tf-sw-slideshow"
          data-preview={1}
          data-mobile={1}
          data-space={0}
          data-loop="true"
          data-auto-play="false"
          data-delay={2000}
          data-speed={1000}
        >
          <div className="swiper-wrapper">
            <div className="swiper-slide" lazy="true">
              <div className="wrap-slider " id="homeslider">
                <LazyLoad>
                  <img
                    className="lazyload img-fluid aboutDesc newYearImg"
                    src={homeslider2}
                    alt="fashion-slideshow"
                  />
                </LazyLoad>
                <div className="sliderTitle ">
                  <h6 className="text-center animate__animated animate__fadeInUp animate__delay-1s aboutDesc my-2">
                    VERTU CRUSH SERIES <br /> AI GOLD EARBUDS
                  </h6>
                  {/* {
                  language == "az" ? (
                    <h3 className="animate__animated animate__fadeInUp animate__delay-1s aboutDesc">
                    {t.vertuTitle} <span className="aboutDesc"> {t.vertuSpan} </span>
                  </h3>
                  )
                  : (
                    <h3 className="animate__animated animate__fadeInUp animate__delay-1s aboutDesc">
                     {t.vertuTitle}  <span className="aboutDesc"> {t.vertuSpan} </span>
                  </h3>
                  )
                 } */}
                  <NavLink to="/qulaqliq">
                    {language == "az" ? (
                      <button className="animate__animated animate__fadeInUp animate__delay-1s">
                        {t.buynow}
                      </button>
                    ) : (
                      <button className="animate__animated animate__fadeInUp animate__delay-1s">
                        {t.buynow}
                      </button>
                    )}
                  </NavLink>
                </div>
                <div className="outline"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="tf-marquee marquee-sm bg_blue-3">
        <div className="wrap-marquee">
          {data.map((i, index) => (
            <div className="marquee-item" key={index}>
              <div className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={7}
                  height={6}
                  viewBox="0 0 7 6"
                  fill="none"
                >
                  <path d="M3.5 0L6.53109 5.25H0.468911L3.5 0Z" fill="white" />
                </svg>
              </div>
              <p className="text text-white text-uppercase fw-6 aboutDesc">
                {i.name_show.split(" (")[0]}
              </p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Slider;
