import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";
import slugify from "slugify";
import { LanguageContext } from "../../context/LanguageContext";
import translations from "../../data/langData";
import collections1 from "../../assets/image/collections1.webp";
import collections2 from "../../assets/image/collections2.webp";

const Collections = () => {
  const [data, setData] = useState([]);
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  useEffect(() => {
    Promise.all([axios.get("https://myshops.az/apk/app_prod.php?q=vertu")])
      .then(([dataRes]) => {
        const data = dataRes.data;
        setData(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <>
      <div className="container-fluid my-5">
        <div className="row">
          <div className="col-12">
            <div className="row g-3">
              <div className="col-lg-6 col-sm-12 col-12">
                <div
                  className="imgBox d-flex justify-content-center align-items-end flex-column"
                  style={{
                    background: `url(${collections1})`,
                  }}
                >
                  <h4 className="mb-2 collecTitle">
                    {/* {cleanedName.split(" (")[0]} */}Vertu IronFlip
                  </h4>
                  <div className="d-flex me-5">
                    <NavLink
                      to="/collections"
                      onClick={() => window.scrollTo({ top: 0 })}
                    >
            
                    </NavLink>
                    <NavLink to="/ironflip" onClick={() => window.scrollTo({ top: 0 })}>
                      <button className="ms-4 collecBtn1">
                        {t.buynow.toUpperCase()}
                      </button>
                    </NavLink>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-sm-12 col-12">
                <div
                  className="imgBox d-flex justify-content-center align-items-end flex-column"
                  style={{
                    background: `url(${collections2})`,
                  }}
                >
                  <h4 className="mb-2 collecTitle">
                    {/* {cleanedName.split(" (")[0]} */}Vertu MetaVertu 2
                  </h4>
                  <div className="d-flex me-5">
                    <NavLink
                      to="/collections"
                      onClick={() => window.scrollTo({ top: 0 })}
                    >
            
                    </NavLink>
                    <NavLink to="/max" onClick={() => window.scrollTo({ top: 0 })}>
                      <button className="ms-4 collecBtn1">
                        {t.buynow.toUpperCase()}
                      </button>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Collections;
