import React, { useContext } from "react";
import Slider from "../../pages/HomePages/Slider";
import Collections from "../../pages/HomePages/Collections";
import Products from "../../pages/HomePages/Products";
import ShopByCategories from "../../pages/HomePages/ShopByCategories";
import { Helmet } from "react-helmet";
import { LanguageContext } from "../../context/LanguageContext";
import translations from "../../data/langData";
import WatchSec from "../../pages/HomePages/WatchSec";
import ApiLinkContext from "../../context/ApiLinkContext";
import PreLoader from "../../pages/PreLoader" ;
const Home = () => {
  const { loading } = useContext(ApiLinkContext);
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  return (
    <>
      {loading ? (
        <PreLoader />
      ) : (
        <>
          <Helmet>
            <title>{t.homeHelmet}</title>
          </Helmet>
          <Slider />
          <Collections />
          <Products />
          <WatchSec />
        </>
      )}
      {/* <ShopByCategories/> */}
    </>
  );
};

export default Home;
