import axios from "axios";
import { createContext, useEffect, useState } from "react";

export const ApiLinkContext = createContext();

export const ApiLinkProvider = (props) => {
  const [data,setData] = useState([])
  const [cat,setCat] = useState([])
  const [selectedData,setSelectedData] = useState(null)

  const [loading, setLoading] = useState(true);
  const [err, setErr] = useState(null);


  useEffect(() => {
      Promise.all([
        axios.get('https://myshops.az/apk/app_prod.php?q=vertu'),
        axios.get('https://myshops.az/apk/app_cat.php')
      ])
      .then(([productRes,catRes])=>{
         setData(productRes.data)
         setCat(catRes.data)
         setLoading(false)
      })
      .catch((err)=>{
        setLoading(err)
      })
  }, []);

  return (
    <ApiLinkContext.Provider
      value={{ data,cat ,loading, err }}
    >
      {props.children}
    </ApiLinkContext.Provider>
  );
};

export default ApiLinkContext;