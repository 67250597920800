import React, { useContext, useEffect, useState } from "react";
import logo from "../assets/image/logodark.png";
import { NavLink } from "react-router-dom";
import ModalCart from "../pages/cart/ModalCart";
import { LanguageContext } from "../context/LanguageContext";
import translations from "../data/langData";
import axios from "axios";
import slugify from "slugify";
import LazyLoad from "react-lazy-load";
import ironflip from "../assets/image/collections1.webp";
import metavertu2 from "../assets/image/metavertu2.webp";
import metavertu1 from "../assets/image/metavertu1.webp";

const HeaderTop = () => {
  const { language, changeLanguage } = useContext(LanguageContext);
  const t = translations[language];
  const uniqueParents = new Set();
  const [mobile, setMobile] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [localData, setLocalData] = useState([]);
  const [data, setData] = useState([]);
  const [cat, setCat] = useState([]);
  const [vertu, setVertu] = useState(false);
  const handleModal = () => {
    setModalOpen(!modalOpen);
  };
  const updateLocalData = () => {
    const data = JSON.parse(localStorage.getItem("cart")) || [];
    setLocalData(data);
  };
  useEffect(() => {
    updateLocalData();
    const handleUpdateCart = () => {
      updateLocalData();
    };
    window.addEventListener("cartUpdated", handleUpdateCart);
    return () => {
      window.removeEventListener("cartUpdated", handleUpdateCart);
    };
  }, []); // sonsuz donguye dusur localData
  const handleMobile = () => {
    setMobile(!mobile);
  };
  const handleLanguageChange = (event) => {
    const newLanguage = event.target.value;
    changeLanguage(newLanguage);
  };
  useEffect(() => {
    Promise.all([
      axios.get("https://myshops.az/apk/app_prod.php?q=vertu"),
      axios.get("https://myshops.az/apk/app_cat.php"),
    ]).then(([dataRes, catRes]) => {
      const data = dataRes.data;
      const cat = catRes.data;
      setData(data);
      setCat(cat);
    });
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setVertu(true);
      } else {
        setVertu(false);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [language]);

  const fakeData = [
    { id: 0, title: "IronFlip", img: ironflip },
    { id: 1, title: "MetaVertu Max", img: metavertu2 },
    { id: 2, title: "MetaVertu Curve", img: metavertu1 },
  ];

  return (
    <>
      <div className="tf-top-bar bg_dark line">
        <div className="px_15 lg-px_40">
          <div className="tf-top-bar_wrap grid-3 gap-30 align-items-center">
            <div className="tf-top-bar_left">
              <div className="d-flex gap-30 text_white fw-5">
                <span>
                  <a href="tel:+8989" className="a_tel aboutDesc">
                    *8989
                  </a>
                </span>
                <span>
                  <a
                    href="mailto:contact@vertuaz.com"
                    className="a_mail aboutDesc"
                  >
                    contact@vertuaz.com
                  </a>
                </span>
              </div>
            </div>
            <div className="text-center overflow-hidden">
              <div
                class="swiper tf-sw-top_bar"
                data-preview="1"
                data-space="0"
                data-loop="true"
                data-speed="1000"
                data-delay="2000"
              >
                <div class="swiper-wrapper">
                  <div class="swiper-slide  vertuText">
                    <p class="top-bar-text fw-5 text_white aboutDesc">
                      {vertu ? "*8989" : ""}
                    </p>
                    <select
                      className="image-select center style-default type-languages color-white deleteSelect aboutDesc"
                      id="select"
                      value={language}
                      onChange={handleLanguageChange}
                    >
                      <option value="en">RU</option>
                      <option value="az">AZ</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="top-bar-language tf-cur justify-content-end selectLanguage">
              <div className="tf-languages">
                <select
                  className="image-select center style-default type-languages color-white aboutDesc"
                  id="select"
                  value={language}
                  onChange={handleLanguageChange}
                >
                  <option value="en">RU</option>
                  <option value="az">AZ</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <header id="header" className="header-default">
        <div className="px_15 lg-px_40">
          <div className="row wrapper-header align-items-center">
            <div className="col-md-4 col-3 tf-lg-hidden">
              <a
                onClick={handleMobile}
                data-bs-toggle="offcanvas"
                aria-controls="offcanvasLeft"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={16}
                  viewBox="0 0 24 16"
                  fill="none"
                >
                  <path
                    d="M2.00056 2.28571H16.8577C17.1608 2.28571 17.4515 2.16531 17.6658 1.95098C17.8802 1.73665 18.0006 1.44596 18.0006 1.14286C18.0006 0.839753 17.8802 0.549063 17.6658 0.334735C17.4515 0.120408 17.1608 0 16.8577 0H2.00056C1.69745 0 1.40676 0.120408 1.19244 0.334735C0.978109 0.549063 0.857702 0.839753 0.857702 1.14286C0.857702 1.44596 0.978109 1.73665 1.19244 1.95098C1.40676 2.16531 1.69745 2.28571 2.00056 2.28571ZM0.857702 8C0.857702 7.6969 0.978109 7.40621 1.19244 7.19188C1.40676 6.97755 1.69745 6.85714 2.00056 6.85714H22.572C22.8751 6.85714 23.1658 6.97755 23.3801 7.19188C23.5944 7.40621 23.7148 7.6969 23.7148 8C23.7148 8.30311 23.5944 8.59379 23.3801 8.80812C23.1658 9.02245 22.8751 9.14286 22.572 9.14286H2.00056C1.69745 9.14286 1.40676 9.02245 1.19244 8.80812C0.978109 8.59379 0.857702 8.30311 0.857702 8ZM0.857702 14.8571C0.857702 14.554 0.978109 14.2633 1.19244 14.049C1.40676 13.8347 1.69745 13.7143 2.00056 13.7143H12.2863C12.5894 13.7143 12.8801 13.8347 13.0944 14.049C13.3087 14.2633 13.4291 14.554 13.4291 14.8571C13.4291 15.1602 13.3087 15.4509 13.0944 15.6653C12.8801 15.8796 12.5894 16 12.2863 16H2.00056C1.69745 16 1.40676 15.8796 1.19244 15.6653C0.978109 15.4509 0.857702 15.1602 0.857702 14.8571Z"
                    fill="currentColor"
                  />
                </svg>
              </a>
            </div>
            <div className="col-xl-3 col-md-4 col-6">
              <NavLink to="/" className="logo-header">
                <img src={logo} alt="logo" className="logo aboutDesc" />
              </NavLink>
            </div>
            <div className="col-xl-6 tf-md-hidden">
              <nav className="box-navigation text-center">
                <ul className="box-nav-ul d-flex align-items-center justify-content-center gap-30">
                  <li className="menu-item">
                    <NavLink to="/" className="item-link aboutDesc">
                      {t.home}
                    </NavLink>
                  </li>
                  <li className="menu-item">
                    <NavLink to="/collections" className="item-link aboutDesc">
                      {t.collections}
                      <i className="icon icon-arrow-down" />
                    </NavLink>
                    <div className="sub-menu mega-menu">
                      <div className="container">
                        <div className="row">
                          <div className="col-lg-8">
                            <h6
                              className="text-center"
                              style={{ fontFamily: "Montserrat" }}
                            >
                              {t.vertumodel.toUpperCase()}
                            </h6>
                            <div className="row">
                              {fakeData.map((i) => (
                                <div className="col-4">
                                  <NavLink
                                    to={`/${slugify(i.title).toLowerCase()}`}
                                    onClick={()=>window.scrollTo({top:0})}
                                  >
                                    <img
                                      src={i.img}
                                      alt=""
                                      className="img-fluid fakeImg"
                                    />
                                  </NavLink>
                                  <br />
                                  <h6 className="text-center">{i.title}</h6>
                                  <div className="d-flex justify-content-center align-items-center my-4">
                                    <NavLink
                                      to={`/${slugify(i.title).toLowerCase()}`}
                                      onClick={()=>window.scrollTo({top:0})}
                                    >
                                      <button className="buynow">
                                        {t.buynow.toUpperCase()}{" "}
                                        <i
                                          className="icon icon-arrow1-top-left ms-2"
                                          style={{ fontSize: "9px" }}
                                        ></i>
                                      </button>
                                    </NavLink>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <h6
                              className="text-start"
                              style={{ fontFamily: "Montserrat" }}
                            >
                              {t.categories.toUpperCase()}
                            </h6>
                            <div className="row">
                              {cat.map((i, index) => {
                                const findCat = data.find(
                                  (catId) => catId.category == i.id
                                );
                                if (findCat) {
                                  const parentId = cat.find(
                                    (catItem) => catItem.id == findCat.parent
                                  );
                                  if (parentId) {
                                    if (!uniqueParents.has(parentId.id)) {
                                      uniqueParents.add(parentId.id);
                                      return (
                                        <div className="col-lg-12 mt-2">
                                          <NavLink
                                            to={`/${slugify(
                                              parentId.name_az
                                            ).toLowerCase()}`}
                                            onClick={()=>window.scrollTo({top:0})}
                                          >
                                            <h6 className="fs-6 headerCat">
                                              {language == "az"
                                                ? parentId.name_az
                                                : parentId.name_ru}
                                            </h6>
                                          </NavLink>
                                        </div>
                                      );
                                    }
                                  }
                                }
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="menu-item position-relative">
                    <NavLink
                      to="/about"
                      onClick={() => window.scrollTo({ top: 0 })}
                      className="item-link aboutDesc"
                    >
                      {t.about}
                    </NavLink>
                  </li>
                  {/* <li className="menu-item position-relative">
                    <a href="#" className="item-link">
                      {t.blog}
                    </a>
                  </li> */}
                  <li className="menu-item position-relative">
                    <NavLink to="/contact" className="item-link aboutDesc">
                      {t.contact}
                    </NavLink>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="col-xl-3 col-md-4 col-3">
              <ul className="nav-icon d-flex justify-content-end align-items-center gap-20">
                <li className="nav-cart" onClick={handleModal}>
                  <a data-bs-toggle="modal" className="nav-icon-item">
                    <i className="icon icon-bag" />
                    <span className="count-box aboutDesc">
                      {localData.length ? localData.length : 0}
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>

      {/* MOBILE MENU */}

      <div
        className={`offcanvas offcanvas-start canvas-mb ${
          mobile ? "show" : ""
        }`}
        id="mobileMenu"
      >
        <span
          className="icon-close icon-close-popup"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={() => setMobile(false)}
        />
        <div className="mb-canvas-content">
          <div className="mb-body">
            <ul className="nav-ul-mb" id="wrapper-menu-navigation">
              <li className="nav-mb-item">
                <NavLink
                  to="/"
                  className="collapsed mb-menu-link current"
                  data-bs-toggle="collapse"
                  aria-expanded="true"
                  aria-controls="dropdown-menu-one"
                  onClick={() => setMobile(false)}
                >
                  <span>{t.home}</span>
                </NavLink>
              </li>
              <li className="nav-mb-item">
                <NavLink
                  to="/collections"
                  className="collapsed mb-menu-link current" // - ve +
                  data-bs-toggle="collapse"
                  aria-expanded="true"
                  aria-controls="dropdown-menu-two"
                  onClick={() => setMobile(false)}
                >
                  <span>{t.collections}</span>
                  {/* <span className="btn-open-sub" /> */}
                </NavLink>
              </li>
              <li className="nav-mb-item">
                <NavLink
                  to="/about"
                  className="collapsed mb-menu-link current"
                  data-bs-toggle="collapse"
                  aria-expanded="true"
                  aria-controls="dropdown-menu-three"
                  onClick={() => setMobile(false)}
                >
                  <span>{t.about}</span>
                </NavLink>
              </li>
              {/* <li className="nav-mb-item">
                <NavLink to="#"
                  className="collapsed mb-menu-link current"
                  data-bs-toggle="collapse"
                  aria-expanded="true"
                  aria-controls="dropdown-menu-four"
                   onClick={() => setMobile(false)}
                >
                  <span>{t.blog}</span>
                </NavLink>
              </li> */}
              <li className="nav-mb-item">
                <NavLink
                  to="/contact"
                  className="collapsed mb-menu-link current"
                  data-bs-toggle="collapse"
                  aria-expanded="true"
                  aria-controls="dropdown-menu-five"
                  onClick={() => setMobile(false)}
                >
                  <span>{t.contact}</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <ModalCart toggle={modalOpen} onCLose={handleModal} />
    </>
  );
};

export default HeaderTop;

// {cat.map((i, index) => {
//   const findCat = data.find(
//     (catId) => catId.category == i.id
//   );
//   if (findCat) {
//     const parentId = cat.find(
//       (catItem) => catItem.id == findCat.parent
//     );
//     if (parentId) {
//       if (
//         !uniqueParents.has(parentId.id) &&
//         parentId.name_az !== "Kabel" && parentId.name_az !== "Qulaqlıq"
//       ) {
//         uniqueParents.add(parentId.id);
//         const findData = data.filter(
//           (item) => item.parent == parentId.id
//         );
//         return (
//           <div className="col-lg-2" key={index}>
//             <div className="mega-menu-item">
//               <div className="menu-heading aboutDesc">
//                 {language == "az"
//                   ? parentId.name_az
//                   : parentId.name_ru}
//               </div>
//               <ul className="menu-list">
//                 {findData
//                   .slice(0, 6)
//                   .map((i, index) => (
//                     <li
//                       key={index}
//                       onMouseEnter={() =>
//                         setHoveredImage(i.icon)
//                       }
//                       onMouseLeave={() =>
//                         setHoveredImage(null)
//                       }
//                     >
//                       <NavLink
//                         to={`/collections/${slugify(
//                           `${i.name_show}-${
//                             i.memory + "GB"
//                           }-${i.value}`
//                         ).toLowerCase()}`}
//                         className="menu-link-text link aboutDesc"
//                       >
//                         {i.name_show.split(" (")[0]}{" "}
//                         {i.value}
//                       </NavLink>
//                     </li>
//                   ))}
//               </ul>
//             </div>
//           </div>
//         );
//       }
//     }
//   }
// })}
