import React from "react";

const PreLoader = () => {
  return (
    <>
      <div className="preload preload-container">
        <div className="preload-logo">
          <div className="spinner" />
        </div>
      </div>
    </>
  );
};

export default PreLoader;
