import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./assets/style/style.scss";
import { LanguageProvider } from "./context/LanguageContext";
import "animate.css";
import { ApiLinkProvider } from "./context/ApiLinkContext";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <ApiLinkProvider>
      <LanguageProvider>
        <App />
      </LanguageProvider>
    </ApiLinkProvider>
  </>
);
